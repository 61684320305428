import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/tmp/2d7e0c78/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "forms"
    }}>{`Forms`}</h1>
    <p>{`Non-binary Gender labels.`}</p>
    <p>{`Indicate required field.`}</p>
    <p>{`Text Fields – more here`}</p>
    <p>{`Pulldown menu – more here`}</p>
    <p>{`Checkbox – more here`}</p>
    <p>{`Radio Button – more here`}</p>
    <p>{`Captcha – more here`}</p>
    <p>{`Submit Button – more here`}</p>
    <p>{`Form Element Feedback`}</p>
    <p>{`Alert color bar over field, icon with message`}</p>
    <p>{`Standard design`}</p>
    <p>{`Background color`}</p>
    <p>{`Button size`}</p>
    <p>{`Header/input box colors`}</p>
    <p>{`Button color`}</p>
    <p>{`Button scaling for tablet/mobile`}</p>
    <p>{`How much variation is ok within one site?`}</p>
    <p>{`Captcha scaling`}</p>
    <p>{`Text contrast`}</p>
    <p>{`Mobile views`}</p>
    <p>{`Error message should appear for each field, with color and X icon`}</p>
    <p>{`Stroke around field as error color`}</p>
    <p>{`Define form background-color.`}</p>
    <h2 {...{
      "id": "basic-usage"
    }}>{`Basic usage`}</h2>
    <h3 {...{
      "id": "html"
    }}>{`HTML`}</h3>
    <p><inlineCode parentName="p">{`here`}</inlineCode></p>
    <h3 {...{
      "id": "markdown"
    }}>{`MARKDOWN`}</h3>
    <p><inlineCode parentName="p">{`here`}</inlineCode></p>
    <h2 {...{
      "id": "properties"
    }}>{`Properties`}</h2>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      